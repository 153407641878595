.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  box-shadow: 0px 0px 16px rgb(15 47 130 / 29%);
}

.App-link {
  color: #61dafb;
}

.header {
  z-index: 3 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.list_con {
  height: 15rem;
  overflow: scroll
}

.res_aside {
  width: 100px !important;
}

.res_head {
  width: 100px !important;
}

.app_header_res {
  padding-left: 100px !important;
}

.app_content_res {
  margin-left: 100px !important;
}

.res_icon {
  width: 85px !important;
}



/* @media screen and (min-width: 992px) {
  .res_aside {
    width: 200px !important;
  }

  .res_head {
    width: 200px !important;
  }

  .app_header_res {
    padding-left: 200px !important;
  }

  .app_content_res {
    margin-left: 200px !important;
  }

  .res_icon {
    width: 24px !important;
  }
} */



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bordered {
  border-color: #383838;
  border-width: 3px;
  border-style: solid;
}

.customViewer {
  border-color: #fa5b35;
  border-width: 1px;
  border-style: solid;
}
.customCanvas {
  height: 500px;
  max-height: 500px;
  min-width: 500px;
  max-width: 500px;
  overflow: auto;
}
.customPrevBtn {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  width: auto;
  color: #fa5b35;
  background-color: #f3f3f3;
  border-color: #fa5b35;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
}
.customNextBtn {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  width: auto;
  color: #fa5b35;
  background-color: #f3f3f3;
  border-color: #333333;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
}
.customResetBtn {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  width: auto;
  color: #45fa35;
  background-color: #f3f3f3;
  border-color: #45fa35;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
}
.customPages {
  font-size: medium;
  margin: 0.5rem;
}
.customWrapper {
  height: 40px;
  font-size: 24px;
  padding: 12px;
  padding-bottom: 0;
  background-color: #fff;
}

.congratulation-page {
  background-color: #f7f7f7;
  /* padding: 20px; */
  text-align: center;
}

.congratulation-header {
  background-color: #0f2f82;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.congratulation-header img {
  width: 100px;
  /* height: 50px; */
  margin: 10px;
}

.congratulation-content {
  margin-top: 20px;
}

.congratulation-content img {
  width: 100px;
  height: 100px;
  margin: 20px;
}

.next-step-btn {
  background-color: #0f2f82;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.next-step-btn:hover {
  background-color:#7393e3;
}

